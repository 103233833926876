import "./style.css";
import * as dat from "dat.gui";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper.js";
import labelVertexShader from "./shaders/label/labelVertex.glsl";
import labelFragmentShader from "./shaders/label/labelFragment.glsl";
import postFXVertexShader from "./shaders/postFX/postFXVertex.glsl";
import postFXFragmentShader from "./shaders/postFX/postFXFragment.glsl";

/**
 * Base
 */
// Debug
const debugObject = {};
const gui = new dat.GUI({
  width: 400,
});

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();
const postFXScene = new THREE.Scene();

/**
 * Mouse
 */
const mouse = new THREE.Vector2();
window.addEventListener("mousemove", (event) => {
  mouse.x = -((event.clientX / sizes.width) * 2 - 1);
  mouse.y = (event.clientY / sizes.height) * 2 - 1;
});

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  // Update Render Targets
  renderBufferA.setSize(
    sizes.width * Math.min(window.devicePixelRatio, 2),
    sizes.height * Math.min(window.devicePixelRatio, 2)
  );
  renderBufferB.setSize(
    sizes.width * Math.min(window.devicePixelRatio, 2),
    sizes.height * Math.min(window.devicePixelRatio, 2)
  );

  // Update uniforms
  postFXMaterial.uniforms.uResolution.value.set(
    sizes.width,
    sizes.height,
    Math.min(window.devicePixelRatio, 2)
  );

  // Update label texture
  updateLabelTexture();
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.x = 0;
camera.position.y = 2;
camera.position.z = -10;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.outputEncoding = THREE.sRGBEncoding;

debugObject.clearColor = "#010f0f";
renderer.setClearColor(debugObject.clearColor);
gui.addColor(debugObject, "clearColor").onChange(() => {
  renderer.setClearColor(debugObject.clearColor);
});

/**
 * Frame Buffer
 */

let renderBufferA = new THREE.WebGLRenderTarget(
  sizes.width * Math.min(window.devicePixelRatio, 2),
  sizes.height * Math.min(window.devicePixelRatio, 2)
);

let renderBufferB = new THREE.WebGLRenderTarget(
  sizes.width * Math.min(window.devicePixelRatio, 2),
  sizes.height * Math.min(window.devicePixelRatio, 2)
);

/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader();

// Draco loader
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath("draco/");

// GLTF loader
const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

/**
 * Textures
 */
const LABEL_TEXT = "KANA";
let labelTextureCanvas;

const updateLabelTexture = () => {
  // Canvas and corresponding context2d to be used for drawing the text
  labelTextureCanvas = document.createElement("canvas");
  const labelTextureCtx = labelTextureCanvas.getContext("2d");

  // Dynamic texture size based on the device capabilities
  const textureSize = Math.min(renderer.capabilities.maxTextureSize, 4096);
  const relativeFontSize = 20;

  // Size the text canvas
  labelTextureCanvas.width = textureSize;
  labelTextureCanvas.height = textureSize;
  labelTextureCtx.textAlign = "center";
  labelTextureCtx.textBaseline = "middle";

  // Dynamic font sizer based on the texture size
  // ( based on the device capabilities )
  labelTextureCtx.font = `${relativeFontSize}px Bebas Neue`;
  const textWidth = labelTextureCtx.measureText(LABEL_TEXT).width;
  const widthDelta = labelTextureCanvas.width / textWidth;
  const fontSize = relativeFontSize * widthDelta;
  labelTextureCtx.font = `${fontSize}px Bebas Neue`;
  labelTextureCtx.fillStyle = "#eee";
  labelTextureCtx.fillText(
    LABEL_TEXT,
    labelTextureCanvas.width / 2,
    labelTextureCanvas.height / 2
  );
};
updateLabelTexture();

/**
 * Lights
 */

/**
 * Material
 */
const labelMaterial = new THREE.ShaderMaterial({
  vertexShader: labelVertexShader,
  fragmentShader: labelFragmentShader,
  uniforms: {
    uLabelTexture: { value: new THREE.CanvasTexture(labelTextureCanvas) },
  },
  transparent: true,
});

const postFXMaterial = new THREE.ShaderMaterial({
  vertexShader: postFXVertexShader,
  fragmentShader: postFXFragmentShader,
  uniforms: {
    uTexture: { value: null },
    uResolution: {
      value: new THREE.Vector3(
        sizes.width,
        sizes.height,
        Math.min(window.devicePixelRatio, 2)
      ),
    },
    uTime: { value: 0.0 },
    uMouse: { value: mouse },
  },
});

/**
 * Meshes
 */
const labelGeometry = new THREE.PlaneGeometry(2, 2);
const label = new THREE.Mesh(labelGeometry, labelMaterial);
scene.add(label);

const postFX = new THREE.Mesh(labelGeometry, postFXMaterial);
postFXScene.add(postFX);

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();

  // Update uniforms
  postFXMaterial.uniforms.uTime.value = elapsedTime;

  // Do not clear the contents of the canvas on each render
  // In order to achieve our ping-pong effect, we must draw
  // the new frame on top of the previous one!
  renderer.autoClearColor = false;

  // Update controls
  // controls.update();

  // Explicitly set renderBufferA as the framebuffer to render to
  renderer.setRenderTarget(renderBufferA);

  // Render the postFXScene to renderBufferA.
  // This will contain our ping-pong accumulated texture
  renderer.render(postFXScene, camera);

  // Render the original scene container ABC again on top
  renderer.render(scene, camera);

  // Set the device screen as the framebuffer to render to
  // In WebGL, framebuffer "null" corresponds to the default
  // framebuffer!
  renderer.setRenderTarget(null);

  // Assign the generated texture to the sampler variable used
  // in the postFXMesh that covers the device screen
  postFX.material.uniforms.uTexture.value = renderBufferA.texture;

  // Render the postFX mesh to the default framebuffer
  renderer.render(postFXScene, camera);

  // Ping-pong our framebuffer's by swapping them
  // at the end of each frame render
  const temp = renderBufferA;
  renderBufferA = renderBufferB;
  renderBufferB = temp;

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
